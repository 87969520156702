import { SearchProvider } from '@faststore/sdk'
import { useEffect } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import {
  useCategoryViewEvent,
  useDepartmentViewEvent,
} from 'src/utils/gtmEvents'
import type {
  CollectionPageQueryQuery,
  ServerCollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { SearchState } from '@faststore/sdk'
import type { SearchResult } from 'src/components/sections/ProductGalleryV2/types'
import { useLinxCategoryView } from 'src/sdk/linx/hooks/useLinxCategoryView'
import Breadcrumb from 'src/components/ui/Breadcrumb/Breadcrumb'
import type { PlacementResponse } from 'src/utils/placements/fetchPlacementData'

type ServerData = {
  serverData: {
    searchParams: SearchState
    searchResult: SearchResult
    data: any
    placementData: PlacementResponse & { categoryName?: string }
  }
  slug: string
  title: string
  type?: string
}

export type Props = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
  unknown,
  ServerCollectionPageQueryQuery
> &
  ServerData

export function CategoryPageTemplate(props: Props) {
  const {
    serverData,
    serverData: { placementData },
    title,
    type,
  } = props

  const { searchParams, searchResult } = serverData

  const categories =
    serverData.data?.collection?.breadcrumbList?.itemListElement?.map(
      (item: any) => item.name
    )

  const { sendCategoryViewEvent } = useLinxCategoryView({
    categories,
  })

  useEffect(() => {
    sendCategoryViewEvent()
  }, [sendCategoryViewEvent])

  useDepartmentViewEvent()
  useCategoryViewEvent()

  if (serverData === null) {
    return null
  }

  const {
    data: { collection },
  } = serverData

  const titlePage =
    collection?.breadcrumbList.itemListElement[
      collection?.breadcrumbList.itemListElement.length - 1
    ]

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <div className="layout__content mb-6">
        <Breadcrumb
          breadcrumbList={collection?.breadcrumbList.itemListElement}
        />
      </div>

      <ProductGalleryV2
        title={titlePage?.name ?? title}
        type="category"
        dataSports={null}
        pageType={type}
        searchResult={searchResult}
        placementData={placementData}
      />
    </SearchProvider>
  )
}
